import '../scss/index.scss';
import $ from 'jquery';
// Load jquery for the full page.
window.$ = window.jQuery = require('jquery').noConflict();

// Declare global variable for Wufoo forms
var i;

function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this,
            args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

/////////////////////
// START slideshow //

class Slideshow {
    static slideshows = [];
    // ms before auto-advance
    period = 5000;

    constructor(el) {
        this.el = el;

        // Collect slides
        this.slides = el.querySelectorAll('.slide');
        if (this.slides.length === 0) {
            console.error('Empty slideshow');
            return;
        }

        // Ensure captioned slides have a caption
        this.slides.forEach((slide) => {
            const caption = slide.querySelector('.slideshow-caption');
            if (caption && caption.innerHTML.trim() === '') {
                caption.innerText = slide
                    .querySelector('img')
                    .getAttribute('alt');
            }
        });

        // Initialise
        el.classList.add('active');
        this.selected = 0;
        this.redraw();
        this.interval = null;
        if (!el.matches(':hover')) {
            this.play();
        }

        // Event handlers
        el.querySelector('.cycle-prev').addEventListener('click', (e) =>
            this.prev()
        );
        el.querySelector('.cycle-next').addEventListener('click', (e) =>
            this.next()
        );
        el.addEventListener('mouseover', (e) => this.stop());
        el.addEventListener('mouseout', (e) => this.play());
    }
    redraw() {
        this.slides.forEach((slide) => {
            slide.classList.remove('selected');
        });
        this.slides[this.selected].classList.add('selected');
    }
    play() {
        if (this.interval === null) {
            this.interval = setInterval(() => this.next(), this.period);
        }
    }
    stop() {
        if (this.interval !== null) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }
    next() {
        this.selected += 1;
        if (this.selected >= this.slides.length) {
            this.selected = 0;
        }
        this.redraw();
    }
    prev() {
        this.selected -= 1;
        if (this.selected < 0) {
            this.selected = this.slides.length - 1;
        }
        this.redraw();
    }

    destroy() {
        this.stop();
        this.el.classList.remove('active');
        this.constructor.slideshows = this.constructor.slideshows.filter(
            (slideshow) => slideshow !== this
        );
    }

    static create(el) {
        const slideshow = new Slideshow(el);
        this.slideshows.push(slideshow);
        return slideshow;
    }

    static clear() {
        const slideshows = this.slideshows.slice();
        slideshows.forEach((slideshow) => slideshow.destroy());
    }
}

$(() => {
    document
        .querySelectorAll('.slideshow')
        .forEach((el) => Slideshow.create(el));
});

///////////////////
// END slideshow //
///////////////////

$(document).ready(function () {
    // Mobile menu
    $('#mobileMenuButton').click(function () {
        $(this).toggleClass('open');
        $('.headerContainer .menu').toggleClass('open');
        $('.headerContainer .menu li .menu').removeClass('open');
    });

    // Mobile menu non-link anchor tag fix
    $('header .headerContainer .menu .menuItems .navigation nav .menu a').each(
        function () {
            if ($(this).attr('href') === 'None') {
                $(this).removeAttr('href');
            }
        }
    );

    if ($(window).width() < 1001) {
        $('.headerContainer .menuItems .navigation').insertBefore(
            $('.headerContainer .menuItems .action')
        );
        $('.headerContainer .menuItems #headerSearch').insertBefore(
            $('.headerContainer .menuItems #navContainer')
        );

        $('.menu li a').each(function () {
            if (
                $(this).is(':contains("Trip Finder"), :contains("Our Experts")')
            ) {
                $(this).parent().children('.fa-plus').hide();
            }
        });

        $('.menu li .menu li a').each(function () {
            $(this).parent().children('.fa-plus').hide();
        });

        $(document).on('click', '.menu li .fa-plus', function () {
            $(this).addClass('fa-minus');
            $(this).next('.menu').addClass('sub-open');
        });
        $(document).on('click', '.menu li .fa-minus', function () {
            $(this).removeClass('fa-minus');
            $(this).addClass('fa-plus');
            $(this).next('.menu').removeClass('sub-open');
        });
    }

    // Move partner nav into mobile menu
    if ($(window).width() < 1001) {
        $('.partner-nav').appendTo('#navContainer');
    }

    // Travel Alert bar cookie
    $(document).on('click', '#travelAlertClose', function () {
        document.cookie = 'hideAlert=true';
        $('.travelAlert').hide();
    });

    if (document.cookie.indexOf('hideAlert=') != -1) {
        $('.travelAlert').hide();
    }

    // Nav scroll to section
    var header = $('.header').outerHeight();
    $('#navContainer .menu-option a').click(function () {
        $('html, body').animate(
            {
                scrollTop: $($.attr(this, 'href')).offset().top - header,
            },
            500
        );
        return false;
    });

    // Add iframes into text-wrappers in order to set width and height
    $('.species-detail-page iframe').each(function () {
        $(this).appendTo($(this).prev('.text-wrapper'));
        $(this).height($(this).width() * 0.6);
    });

    // Contact/Catalog form dropdowns
    $('form .travel_interest').on('click', function () {
        if ($(this).next('.dropdown-section').height() === 0) {
            $(this).next('.dropdown-section').css('max-height', '100000px');
        } else {
            $(this).next('.dropdown-section').css('max-height', 0);
        }
    });

    // Tailor Made form
    $('.country-list').on('click', function () {
        if ($('.tailor-made-countries').is(':visible')) {
            $('.tailor-made-countries').hide();
            $(this).children('svg').addClass('fa-sort-down');
        } else {
            $('.tailor-made-countries').show();
            $(this).children('svg').addClass('fa-sort-up');
        }
    });

    // Tailor Made inquire button styles
    $('.tailor-made-inquire-form-buttons-duration label input').on(
        'click',
        function () {
            if ($(this).parent().hasClass('checked')) {
                $(this).parent().removeClass('checked');
            } else {
                $('.tailor-made-inquire-form-buttons-duration label').each(
                    function () {
                        $(this).removeClass('checked');
                    }
                );
                $(this).parent().addClass('checked');
            }
        }
    );
    $('.tailor-made-inquire-form-buttons-when label input').on(
        'click',
        function () {
            if ($(this).parent().hasClass('checked')) {
                $(this).parent().removeClass('checked');
            } else {
                $('.tailor-made-inquire-form-buttons-when label').each(
                    function () {
                        $(this).removeClass('checked');
                    }
                );
                $(this).parent().addClass('checked');
            }
        }
    );
    $('.tailor-made-inquire-form-buttons-reason label input').on(
        'click',
        function () {
            if ($(this).parent().hasClass('checked')) {
                $(this).parent().removeClass('checked');
            } else {
                $('.tailor-made-inquire-form-buttons-reason label').each(
                    function () {
                        $(this).removeClass('checked');
                    }
                );
                $(this).parent().addClass('checked');
            }
        }
    );
    $('.tailor-made-inquire-form-buttons-party label input').on(
        'click',
        function () {
            if ($(this).parent().hasClass('checked')) {
                $(this).parent().removeClass('checked');
            } else {
                $('.tailor-made-inquire-form-buttons-party label').each(
                    function () {
                        $(this).removeClass('checked');
                    }
                );
                $(this).parent().addClass('checked');
            }
        }
    );
    $('.tailor-made-inquire-form-buttons-accommodations label input').on(
        'click',
        function () {
            if ($(this).parent().hasClass('checked')) {
                $(this).parent().removeClass('checked');
            } else {
                $(
                    '.tailor-made-inquire-form-buttons-accommodations label'
                ).each(function () {
                    $(this).removeClass('checked');
                });
                $(this).parent().addClass('checked');
            }
        }
    );

    // Tour accomodations/transport slideshows
    if ($('.slideshowMini').length) {
        $('.slideshowMini').each(function () {
            if ($(this).children('.slide').length === 1) {
                $(this).children('.slide').removeClass('slide');
                $(this).children('.cycle-prev').hide();
                $(this).children('.cycle-next').css('opacity', 0);
            }
        });
    }

    // ======================================================================
    // ========================== BEGIN TRIPFINDER ==========================
    // ======================================================================

    // === Add see more button for tours with more than 3 departure dates ===
    function limitDepartureDates() {
        $('.searchResults .tourDetail ul').each(function () {
            if ($(this).children('li').length > 3) {
                $(this).children('li').hide();
                $(this).children('li').slice(0, 3).show();
                $(this).children('.see-more').show();
            }
        });

        $('.searchResults .tourDetail ul .see-more').click(function () {
            $(this).parent().children('li').show();
            $(this).hide();
        });
    }
    limitDepartureDates();

    // === Callback for once the promo billboard has been updated ===
    function redrawPromoBillboard() {
        // Limit number of departure dates shown
        limitDepartureDates();

        // Call promo slideshow function
        const slideshowEl = this.querySelector('.slideshow');
        if (slideshowEl) {
            Slideshow.create(slideshowEl);
        }
    }

    // ================ Section expand/collapse ==================
    if ($(window).width() < 1001) {
        $('.facet-label').each(function () {
            if (
                !$(this)
                    .parent()
                    .children('ul')
                    .children('li')
                    .children('input')
                    .is(':checked')
            ) {
                $(this)
                    .parent()
                    .addClass('collapsed')
                    .css({
                        height: $(this).outerHeight(),
                    });
            }
        });
    }
    $('.facet-label').click(function () {
        if ($(this).parent().hasClass('collapsed')) {
            var height =
                $(this).parent().children('.facet-label').outerHeight() +
                $(this).parent().children('ul').outerHeight();
            $(this)
                .parent()
                .removeClass('collapsed')
                .animate(
                    {
                        height: height,
                    },
                    function () {
                        $(this).css('height', 'auto');
                    }
                );
        } else {
            $(this)
                .parent()
                .addClass('collapsed')
                .animate({
                    height: $(this).outerHeight(),
                });
        }
    });

    // ================= Trip Finder region toggle switch =================
    $('#tripfinder-form .destinations h3 .slider').click(function () {
        var currentUrl = location.href;
        var region = $(this).attr('data-region');
        region = region.replace('&', '%26');
        region = region.replace(/\s/g, '%20');

        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).parent().parent().nextUntil('h3').css('display', 'none');
            $(this).parent().siblings('img').removeClass('gold');

            // Remove regions URL parameter
            currentUrl = currentUrl
                .replace('&regions=' + region, '')
                .replace('regions=' + region, '');
            currentUrl = currentUrl.replace(
                /&? + region + =([^&]$|[^&]*)/i,
                ''
            );

            // Get country name
            var country = $(this)
                .parent()
                .parent()
                .clone()
                .children()
                .remove()
                .end()
                .text();
            // Remove country name whitespace and convert characters
            country = country.replace(/\s/g, '');
            country = country.replace(/&([^&]$|[^&]*)/g, '');
            // Remove all instances of country URL parameter
            country = 'countries=' + country;
            var countryRemove = new RegExp(country, 'g');
            currentUrl = currentUrl.replace(countryRemove, 'remove');
            currentUrl = currentUrl.replace(/&?remove([^&]$|[^&]*)/g, '');
            // Update URL
            window.history.pushState(null, null, currentUrl);
        } else {
            $(this).addClass('active');
            $(this)
                .parent()
                .parent()
                .nextUntil('h3')
                .css('display', 'inline-block');
            $(this).parent().siblings('img').addClass('gold');
            $(this)
                .parent()
                .parent()
                .nextUntil('h3')
                .children('input')
                .removeAttr('checked');
            $(this).parent().parent().nextUntil('h3').removeClass('lightgray');

            var region = 'regions=' + region;

            if (window.location.href.indexOf(region) > -1) {
            } else {
                if (window.location.href.indexOf('?') > -1) {
                    currentUrl = currentUrl += '&' + region;
                    window.history.pushState(null, null, currentUrl);
                } else {
                    currentUrl = currentUrl += '?' + region;
                    window.history.pushState(null, null, currentUrl);
                }
            }
        }

        // Reload search number
        $('#searchHeaderWrapper').load(location.href + ' #searchHeader');
        // Reload search results
        $('#searchWrapper').load(location.href + ' #searchResults');
        // Reload promo billboard
        Slideshow.clear();
        $('#promoBillboardWrapper').load(
            location.href + ' #promoBillboard',
            redrawPromoBillboard
        );
    });

    // =========== Retain region section state on page refresh ===========
    $('#main.tripfinder form#tripfinder-form ul h3').each(function () {
        var regionName = $(this).find('.slider').data('region');
        // Replace region name white space
        if (regionName != null) {
            regionName = regionName.replace(/(\w+).*/, '$1');
        }
        if (window.location.href.indexOf('regions=' + regionName) > -1) {
            $(this).find('.slider').addClass('active');
            $(this).find('input').prop('checked', true);
            $(this)
                .children()
                .parent()
                .nextUntil('h3')
                .css('display', 'inline-block');
            $(this).children('img').addClass('gold');
            $(this)
                .children()
                .parent()
                .nextUntil('h3')
                .children('input')
                .removeAttr('checked');
            $(this)
                .children()
                .parent()
                .nextUntil('h3')
                .removeClass('lightgray');
            if ($(this).parent().hasClass('destinations')) {
                $(this)
                    .parent()
                    .parent()
                    .children('.destinations')
                    .parent()
                    .removeClass('collapsed')
                    .css({
                        height: 'auto',
                    });
            }
        }
    });
    // =========== Retain selected input styles on page refresh ==========
    $('.destinations li input').each(function () {
        var urlReplace = $(this)
            .attr('value')
            .replace(/\s/g, '%20')
            .replace(/\&/g, '%26');

        if (window.location.href.indexOf(urlReplace) > -1) {
            $(this).prop('checked', true);
            $(this)
                .parent()
                .parent()
                .children('h3')
                .nextUntil('h3')
                .addClass('lightgray');
        }
    });
    $('#tripfinder-form li input').each(function () {
        if ($(this).is(':checked')) {
            $(this).parent().parent().children('li').addClass('lightgray');
        }
    });

    // ====== Gray out surrounding options once option is clicked and on page reload ======
    $('ul li input').click(function () {
        $(this).parent().nextUntil('h3').addClass('lightgray');
        $(this).parent().prevUntil('h3').addClass('lightgray');
        $(this)
            .parent()
            .parent()
            .children('li')
            .each(function () {
                if ($(this).children('input').is(':checked')) {
                } else {
                    $(this).addClass('lightgray');
                }
            });
        if (
            $(this)
                .parent()
                .parent()
                .children('li')
                .children('input:checkbox:checked').length == 0
        ) {
            $(this).parent().parent().children('li').removeClass('lightgray');
        }
    });

    // ================ Retain destination list state if selected ================
    $('#tripfinder-form ul.destinations li.selected').each(function () {
        $(this).prevUntil('h3').css('display', 'inline-block');
        $(this).nextUntil('h3').css('display', 'inline-block');
        $(this).css('display', 'inline-block');
        $(this).prevAll('h3').first().children('img').addClass('gold');
        $(this)
            .prevAll('h3')
            .first()
            .children('.switch')
            .children('input')
            .prop('checked', true);
        $(this)
            .prevAll('h3')
            .first()
            .children('.switch')
            .children('.slider')
            .addClass('active');
    });

    $('ul.destinations li input').click(function () {
        $('#tripfinder-form').submit();
    });

    // ================ Sort bar functionality ================
    // trip name
    $('#tripName').click(function () {
        // get current URL parameters
        var currentUrl = location.href;

        // add question mark if not already there
        if (window.location.href.indexOf('?') > -1) {
        } else {
            currentUrl = currentUrl += '?';
        }

        // remove current sort parameters and add new one
        currentUrl = currentUrl
            .replace('&sort=min_price_adult', '')
            .replace('&sort=-min_price_adult', '')
            .replace('&sort=departure_dates', '')
            .replace('&sort=-departure_dates', '');
        if (window.location.href.indexOf('sort=sort_title') > -1) {
            currentUrl = currentUrl.replace('&sort=sort_title', '');
            window.history.pushState(
                null,
                null,
                (currentUrl += '&sort=-sort_title')
            );
            $('.sort-option i').css('opacity', 0);
            $('#tripName i').css('opacity', 1).addClass('fa-caret-up');
        } else {
            currentUrl = currentUrl.replace('&sort=-sort_title', '');
            window.history.pushState(
                null,
                null,
                (currentUrl += '&sort=sort_title')
            );
            $('.sort-option i').css('opacity', 0);
            $('#tripName i').css('opacity', 1).removeClass('fa-caret-up');
        }

        $('#searchWrapper').load(location.href + ' #searchResults');
    });

    // price
    $('#tripPrice').click(function () {
        // get current URL parameters
        var currentUrl = location.href;

        // add question mark if not already there
        if (window.location.href.indexOf('?') > -1) {
        } else {
            currentUrl = currentUrl += '?';
        }

        // remove current sort parameters and add new one
        currentUrl = currentUrl
            .replace('&sort=sort_title', '')
            .replace('&sort=-sort_title', '')
            .replace('&sort=departure_dates', '')
            .replace('&sort=-departure_dates', '');
        if (window.location.href.indexOf('sort=min_price_adult') > -1) {
            currentUrl = currentUrl.replace('&sort=min_price_adult', '');
            window.history.pushState(
                null,
                null,
                (currentUrl += '&sort=-min_price_adult')
            );
            $('.sort-option i').css('opacity', 0);
            $('#tripPrice i').css('opacity', 1).addClass('fa-caret-up');
        } else {
            currentUrl = currentUrl.replace('&sort=-min_price_adult', '');
            window.history.pushState(
                null,
                null,
                (currentUrl += '&sort=min_price_adult')
            );
            $('.sort-option i').css('opacity', 0);
            $('#tripPrice i').css('opacity', 1).removeClass('fa-caret-up');
        }

        $('#searchWrapper').load(location.href + ' #searchResults');
    });

    // departure date
    $('#tripDate').click(function () {
        // get current URL parameters
        var currentUrl = location.href;

        // add question mark if not already there
        if (window.location.href.indexOf('?') > -1) {
        } else {
            currentUrl = currentUrl += '?';
        }

        // remove current sort parameters and add new one
        currentUrl = currentUrl
            .replace('&sort=sort_title', '')
            .replace('&sort=-sort_title', '')
            .replace('&sort=min_price_adult', '')
            .replace('&sort=-min_price_adult', '');
        if (window.location.href.indexOf('sort=departure_dates') > -1) {
            currentUrl = currentUrl.replace('&sort=departure_dates', '');
            window.history.pushState(
                null,
                null,
                (currentUrl += '&sort=-departure_dates')
            );
            $('.sort-option i').css('opacity', 0);
            $('#tripDate i').css('opacity', 1).addClass('fa-caret-up');
        } else {
            currentUrl = currentUrl.replace('&sort=-departure_dates', '');
            window.history.pushState(
                null,
                null,
                (currentUrl += '&sort=departure_dates')
            );
            $('.sort-option i').css('opacity', 0);
            $('#tripDate i').css('opacity', 1).removeClass('fa-caret-up');
        }

        $('#searchWrapper').load(location.href + ' #searchResults');
    });

    // ================ Retain sort bar arrow state if link is shared or refreshed ================
    if (window.location.href.indexOf('sort_title') > -1) {
        $('.sort-option i').css('opacity', 0);
        $('#tripName i').css('opacity', 1).addClass('fa-caret-up');
    }
    if (window.location.href.indexOf('=sort_title') > -1) {
        $('.sort-option i').css('opacity', 0);
        $('#tripName i').css('opacity', 1).removeClass('fa-caret-up');
    }
    if (window.location.href.indexOf('-min_price_adult') > -1) {
        $('.sort-option i').css('opacity', 0);
        $('#tripPrice i').css('opacity', 1).addClass('fa-caret-up');
    }
    if (window.location.href.indexOf('=min_price_adult') > -1) {
        $('.sort-option i').css('opacity', 0);
        $('#tripPrice i').css('opacity', 1).removeClass('fa-caret-up');
    }
    if (window.location.href.indexOf('-departure_dates') > -1) {
        $('.sort-option i').css('opacity', 0);
        $('#tripDate i').css('opacity', 1).addClass('fa-caret-up');
    }
    if (window.location.href.indexOf('=departure_dates') > -1) {
        $('.sort-option i').css('opacity', 0);
        $('#tripDate i').css('opacity', 1).removeClass('fa-caret-up');
    }

    // ================ Retain search query parameter on filter option click ================
    function getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (pair[0] == variable) {
                return pair[1];
            }
        }
        return false;
    }

    var searchQuery = getQueryVariable('q');
    if (searchQuery === false) {
        $('#searchQuery').remove();
    } else {
        $('#searchQuery').val(searchQuery);
    }

    // ================ Intercept form submit and load only search results with new URL parameters ================
    $('#tripfinder-form ul li input').click(function () {
        // Submit form
        $('#tripfinder-form').submit();

        // Add question mark to url
        var url = location.href + '?';
        if (window.location.href.indexOf('?') > -1) {
        } else {
            history.pushState(null, null, url);
        }

        // Add or remove url parameter on filter option click
        var value = encodeURIComponent($(this).val());
        var name = encodeURIComponent($(this).attr('name'));
        var urlParam = name + '=' + value;
        urlParam = '&' + urlParam;
        var urlParamLink = location.href + urlParam;
        var currentUrl = location.href;
        if (window.location.href.indexOf(urlParam) > -1) {
            currentUrl = location.href.replace(urlParam, '');
            history.pushState(null, null, currentUrl);
        } else {
            history.pushState(null, null, urlParamLink);
        }

        // Remove sort on filter click
        $('.search-sort-bar .sort-options .sort-option i.fa')
            .removeClass('.fa-caret-up')
            .css('opacity', 0);

        currentUrl = location.href.replace(/&?sort=([^&]$|[^&]*)/i, '');
        history.pushState(null, null, currentUrl);
    });

    // ================ Refresh search results on filter option click ================
    var xhr;

    var tripfinderSubmitForm = function (e) {
        e.preventDefault();

        $('#searchWrapper').css('opacity', '0.5');

        if (xhr && xhr.readyState != 4) {
            xhr.abort();
        }

        xhr = $.ajax({
            url: $(this).attr('action'),
            type: 'GET',
            data: $(this).serialize(),
            success: function (data, url) {
                // Reload search results
                $('#searchWrapper').load(location.href + ' #searchResults');
                // Reload search results number
                $('#searchHeaderWrapper').load(
                    location.href + ' #searchHeader',
                    function () {
                        // Add opacity to search results while loading
                        $('#searchWrapper').css('opacity', '1');
                    }
                );
                // Reload promo billboard
                Slideshow.clear();
                $('#promoBillboardWrapper').load(
                    location.href + ' #promoBillboard',
                    redrawPromoBillboard
                );
            },
        });
    };

    $('#tripfinder-form').on('submit', function (e) {
        debounce(tripfinderSubmitForm(e), 150, {});
    });

    // ================ Back to filters button ================
    $('#backFilters').click(function () {
        $('html, body').animate(
            {
                scrollTop: 0,
            },
            500
        );
    });

    if ($(window).width() > 1000) {
        $(window).on('scroll', function () {
            if ($(window).scrollTop() < 2500) {
                $('#backFilters').fadeOut(100);
            } else {
                $('#backFilters').fadeIn();
            }
        });
    }

    // ================ Last call promo departure dates in red ================
    $(
        '#tripfinderTrips #searchWrapper #searchResults .row.searchResults ul li'
    ).each(function () {
        if ($(this).is(':contains("off per traveler")')) {
            $(this).css('color', '#D42930');
        }
    });

    // ================ Don't display tour update special offer departures on special offer page ================
    if (window.location.href.indexOf('Special%20Offers') > -1) {
        $('.row.searchResults .columns ul li.special-offers').each(function () {
            if (
                $(this).is(':contains("|")') ||
                $(this).is(':contains("airfare")')
            ) {
                $(this).parent().parent().parent().addClass('dont-hide');
            }
        });

        $('.row.searchResults').hide();
        $('.row.thinLineFullGray').hide();
        $('.row.searchResults.dont-hide').show();
        $('<div class="row thinLineFullGray"></div>').insertBefore(
            $('.row.searchResults.dont-hide')
        );

        $resultsTotal = $('.row.searchResults.dont-hide').length;
        $('#searchHeader h1').text('SEARCH RESULTS (' + $resultsTotal + ')');
    }

    // ================ Last call promo departure dates remove airfare included text ================
    $('li.special-offers').each(function () {
        if ($(this).is(':contains("Airfare Included")')) {
            $(this).html(
                $(this)
                    .html()
                    .replace(/\| Airfare Included\!/g, '')
            );
            if (
                $(this).parent().parent().find('p').is(':contains("airfare")')
            ) {
            } else {
                $(this)
                    .parent()
                    .parent()
                    .find('p')
                    .append(
                        '<div style="width: 90px; color: rgb(212, 41, 48); font-size: 11px; line-height: 12px;">Price includes airfare</div>'
                    );
            }
        }
    });

    // ======================================================================
    // ============================ END TRIPFINDER ==========================
    // ======================================================================
});

var x;
$(document).ready(function () {
    let slideshowMiniActive = [];
    $('#tour-center-menu')
        .find('a')
        .each(function (i, elem) {
            $(elem).on('click', function (e) {
                e.preventDefault();

                var href = $(e.currentTarget).attr('href');
                if (!href) {
                    return;
                }
                $.get(href, function (data) {
                    slideshowMiniActive.forEach((slideshow) => {
                        slideshow.destroy();
                    });
                    slideshowMiniActive = [];

                    $('#tour-content').html(data);
                    history.pushState('', '', href.replace('js/', ''));

                    $('#tour-content')
                        .find('.slideshowMini')
                        .each((i, el) => {
                            slideshowMiniActive.push(Slideshow.create(el));
                        });

                    $('#tour-center-menu')
                        .find('li.active')
                        .each(function (i, link) {
                            $(link).removeClass('active');
                        });
                    x = $(e.currentTarget);
                    $(e.currentTarget).find('li').toggleClass('active');
                    if (
                        $(e.currentTarget).attr('href').indexOf('itinerar') > -1
                    ) {
                        $(e.currentTarget)
                            .parents('ul')
                            .find('li.itineraries')
                            .toggleClass('active');
                    }
                    $('#tour-center-menu ul a li')
                        .parent()
                        .css({ 'pointer-events': 'auto' });
                    $('#tour-center-menu ul a li.active')
                        .parent()
                        .css({ 'pointer-events': 'none' });
                }).fail(function () {
                    window.location = href;
                });
            });
        });

    $('#tour-center-menu ul a li.active')
        .parent()
        .css({ 'pointer-events': 'none' });
});

// ================================================================
// ====================== CUSTOM TOUR CONTENT =====================
// ================================================================
$(document).ready(function () {
    // Best of Africa by Private Jet: A Cultural and Wildlife Journey
    if (
        window.location.href.indexOf('tours/sid-eyJwayI6IDMyN30=') > -1 ||
        window.location.href.indexOf('/tours/africa-private-jet/') > -1 ||
        window.location.href.indexOf('tours/sid-eyJwayI6IDUzNn0') > -1 ||
        window.location.href.indexOf('/tours/across-the-alps/') > -1
    ) {
        $(
            '#main.tour-page .tourTitle h3, #main.tour-page #tour-content, #main.tour-page #tour-center-menu, #main.tour-page .tour-summary-box select'
        ).hide();
    }
});

/* ------------------------------------------------------------------------
 * If the SRC parameter is present, set the src cookie
 * --------------------------------------------------------------------- */
function getURLParameter(name, flags) {
    flags = flags || '';
    return (
        decodeURIComponent(
            (new RegExp(
                '[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)',
                flags
            ).exec(location.search) || [, ''])[1].replace(/\+/g, '%20')
        ) || null
    );
}
function setSRC() {
    var src = getURLParameter('src', 'i');
    if (src && src.indexOf(';') !== -1) {
        // Likely someone is trying to mess with our cookies
        return;
    }
    if (src) {
        var d = new Date();
        d.setTime(d.getTime() + 10 * 24 * 60 * 60 * 1000); //expires in 10 days
        var expires = 'expires=' + d.toGMTString();
        document.cookie = 'src=' + src + '; Path=/; ' + expires;
    }
}
function setSIGNUPSOURCE() {
    var signup_source = getURLParameter('signup_source', 'i');
    if (signup_source && signup_source.indexOf(';') !== -1) {
        // Likely someone is trying to mess with our cookies
        return;
    }
    if (signup_source) {
        var d = new Date();
        d.setTime(d.getTime() + 10 * 24 * 60 * 60 * 1000); //expires in 10 days
        var expires = 'expires=' + d.toGMTString();
        document.cookie =
            'signup_source=' + signup_source + '; Path=/; ' + expires;
    }
}

$(document).ready(function () {
    setSRC();
    setSIGNUPSOURCE();
});

/* ------------------------------------------------------------------------
 * Begin e-commerce data layer pushes
 * --------------------------------------------------------------------- */
document.addEventListener('DOMContentLoaded', function () {
    // Trip Finder
    if (window.location.href.indexOf('/tours/tripfinder/') !== -1) {
        var searchResults = document.querySelector('#searchResults');
        var tourDetails = searchResults.querySelectorAll('.tourDetail');

        for (var i = 0; i < tourDetails.length; i++) {
            var tourDetail = tourDetails[i];
            var tourLink = tourDetail.querySelector('h2 a');

            tourLink.addEventListener('click', function (e) {
                e.preventDefault();

                var tourName = this.textContent;
                var tourPrice =
                    this.parentElement.nextElementSibling.querySelector(
                        '.gold-price'
                    ).textContent;
                tourPrice = tourPrice.replace(/[^\d.]/g, '');
                tourPrice = parseFloat(tourPrice);
                tourPrice = tourPrice.toFixed(2);
                var tourLinkHref = this.href;

                dataLayer.push({
                    event: 'select_item',
                    ecommerce: {
                        items: [
                            {
                                item_list_name: 'Tripfinder',
                                item_name: tourName,
                                price: tourPrice,
                            },
                        ],
                    },
                });

                setTimeout(function () {
                    window.location.href = tourLinkHref;
                }, 100);
            });
        }
    }

    // Tour Page
    if (document.getElementsByClassName('tour-page').length > 0) {
        var tourTitle = document.querySelector('.tourTitle h1').textContent;
        var tourPrice = document.querySelector('.tourTitle h3').textContent;

        if (tourPrice.indexOf('$') !== -1) {
            tourPrice = tourPrice.slice(tourPrice.indexOf('$'));
            tourPrice = tourPrice.replace(/[^\d.]/g, '');
            tourPrice = parseFloat(tourPrice);
            tourPrice = tourPrice.toFixed(2);
        } else {
            tourPrice = 'N/A';
        }

        dataLayer.push({
            event: 'view_item',
            ecommerce: {
                items: [
                    {
                        item_name: tourTitle,
                        price: tourPrice,
                    },
                ],
            },
        });
    }

    // Add to cart
    if (
        window.location.href.indexOf('/tours/') !== -1 &&
        window.location.href.indexOf('/book/') !== -1
    ) {
        var tourTitle = document.querySelector(
            '.booking-destination h1 a'
        ).textContent;
        var departureCode = window.location.pathname;
        departureCode = departureCode.split('book/')[1].split('/')[0];

        dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
                items: [
                    {
                        item_name: tourTitle,
                        item_id: departureCode,
                    },
                ],
            },
        });
    }

    // Checkout
    if (
        window.location.href.indexOf('/tours/') !== -1 &&
        window.location.href.indexOf('/book/') !== -1
    ) {
        var tourForm = document.querySelector('.booking-form');

        tourForm.addEventListener('submit', function (event) {
            event.preventDefault();

            var tourTitle = document.querySelector(
                '.booking-destination h1 a'
            ).textContent;
            var tourPrice = document.querySelectorAll(
                '.prices .booking-left-column p label strong'
            )[2].textContent;
            tourPrice = tourPrice.replace(/[^\d.]/g, '');
            tourPrice = parseFloat(tourPrice);
            tourPrice = tourPrice.toFixed(2);
            var departureDate = document
                .querySelector('.booking-destination h1')
                .textContent.split('for ');
            departureDate = departureDate.length > 1 ? departureDate[1] : '';
            var departureCode = window.location.pathname;
            departureCode = departureCode.split('book/')[1].split('/')[0];
            var numTravelers = document.querySelector(
                'select[name="totalTravelers"]'
            ).value;

            dataLayer.push({
                event: 'begin_checkout',
                ecommerce: {
                    items: [
                        {
                            item_name: tourTitle,
                            item_id: departureCode,
                            price: tourPrice,
                            item_variant: departureDate,
                            quantity: numTravelers,
                        },
                    ],
                },
            });

            setTimeout(function () {
                tourForm.submit();
            }, 500);
        });
    }
});
/* ------------------------------------------------------------------------
 * End e-commerce data layer pushes
 * --------------------------------------------------------------------- */

/* ------------------------------------------------------------------------
 * Begin catalog dropdown sections
 * --------------------------------------------------------------------- */
document.addEventListener('DOMContentLoaded', function () {
    if (document.querySelector('.catalog-section-trigger')) {
        var triggers = document.querySelectorAll('.catalog-section-trigger');

        triggers.forEach(function (trigger) {
            trigger.addEventListener('click', function () {
                var dropdown = this.nextElementSibling;
                if (dropdown.classList.contains('catalog-section-dropdown')) {
                    dropdown.classList.toggle('opened');
                }
            });
        });

        // Prevent form from being collapsed if errors are present
        const errorLists = document.getElementsByClassName('errorlist');

        for (const errorList of errorLists) {
            if (errorList.offsetParent !== null) {
                const dropdown = errorList.closest('.catalog-section-dropdown');

                if (dropdown) {
                    dropdown.classList.add('opened');
                }
            }
        }
    }
});
/* ------------------------------------------------------------------------
 * End catalog dropdown sections
 * --------------------------------------------------------------------- */
